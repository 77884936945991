import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";

const slice = createSlice({
  name: "company_bonuses",
  initialState: {
    list: [],
    loading: false,
    deletion_loading: false,
    saving_loading: false,
    saved: false,
  },

  reducers: {
    companyBonusesRequested: (state, action) => {
      state.list = [];
      state.loading = true;
    },

    companyBonusesReceived: (state, action) => {
      state.list = action.payload.company_bonuses;
      state.loading = false;
    },

    companyBonusesRequestFailed: (state, action) => {
      state.loading = false;
    },

    companyBonusCreationRequested: (state, action) => {
      state.saving_loading = true;
      state.saved = false;
    },

    companyBonusCreationReceived: (state, action) => {
      const { company_bonus } = action.payload;
      state.list.push(company_bonus);
      state.saving_loading = false;
      state.saved = true;
    },

    companyBonusCreationFailed: (state, action) => {
      state.saving_loading = false;
      state.saved = false;
    },

    companyBonusUpdationRequested: (state, action) => {
      state.saving_loading = true;
      state.saved = false;
    },

    companyBonusUpdationReceived: (state, action) => {
      const { company_bonus } = action.payload;

      const index = state.list.findIndex(
        (stat) => stat.id === company_bonus.id
      );
      if (index !== -1) {
        state.list[index] = company_bonus;
      }

      state.saving_loading = false;
      state.saved = true;
    },

    companyBonusUpdationFailed: (state, action) => {
      state.saving_loading = false;
    },

    companyBonusDeletionRequested: (state, action) => {
      state.deletion_loading = true;
    },

    companyBonusDeletionReceived: (state, action) => {
      const { id } = action.payload;
      const index = state.list.findIndex((dep) => dep.id === id);
      state.list.splice(index, 1);
      state.deletion_loading = false;
    },

    companyBonusDeletionFailed: (state, action) => {
      state.deletion_loading = false;
    },

    resetActionStates: (state, action) => {
      state.loading = false;
      state.saving_loading = false;
      state.saved = false;
      state.deletion_loading = false;
    },
  },
});

export default slice.reducer;

const {
  companyBonusesRequested,
  companyBonusesReceived,
  companyBonusesRequestFailed,
  companyBonusCreationRequested,
  companyBonusCreationReceived,
  companyBonusCreationFailed,
  companyBonusUpdationRequested,
  companyBonusUpdationReceived,
  companyBonusUpdationFailed,
  companyBonusDeletionRequested,
  companyBonusDeletionReceived,
  companyBonusDeletionFailed,
  resetActionStates,
} = slice.actions;

const url = "v1/hrms/company_bonuses";

export const getCompanyBonuses = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url,
      onStart: companyBonusesRequested.type,
      onSuccess: companyBonusesReceived.type,
      onError: companyBonusesRequestFailed.type,
    })
  );
};

export const createCompanyBonus = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      method: "POST",
      data: { company_bonus: payload.company_bonus },
      type: "creation",
      onStart: companyBonusCreationRequested.type,
      onSuccess: companyBonusCreationReceived.type,
      onError: companyBonusCreationFailed.type,
      showAlert: true,
      successMessage: "Bonus added successfully!",
    })
  );
};

export const updateCompanyBonus = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "PATCH",
      data: { company_bonus: payload.company_bonus },
      type: "updation",
      onStart: companyBonusUpdationRequested.type,
      onSuccess: companyBonusUpdationReceived.type,
      onError: companyBonusUpdationFailed.type,
      showAlert: true,
      successMessage: "Bonus updated successfully!",
    })
  );
};

export const deleteCompanyBonus = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "DELETE",
      type: "deletion",
      onStart: companyBonusDeletionRequested.type,
      onSuccess: companyBonusDeletionReceived.type,
      onError: companyBonusDeletionFailed.type,
      showAlert: true,
      successMessage: "Bonus deleted successfully!",
    })
  );
};

export const resetActions = () => (dispatch) => {
  return dispatch(resetActionStates());
};
