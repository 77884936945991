import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";

const slice = createSlice({
  name: "company_payroll_settings",
  initialState: {
    settings: [],
    loading: false,
    deletion_loading: false,
    saving_loading: false,
    saved: false,
  },

  reducers: {
    companyPayrollSettingsRequested: (state, action) => {
      state.settings = [];
      state.loading = true;
    },

    companyPayrollSettingsReceived: (state, action) => {
      state.settings = action.payload.settings;
      state.loading = false;
    },

    companyPayrollSettingsRequestFailed: (state, action) => {
      state.loading = false;
    },

    companyPayrollSettingCreationRequested: (state, action) => {
      state.saving_loading = true;
      state.saved = false;
    },

    companyPayrollSettingCreationReceived: (state, action) => {
      const { company_payroll_setting } = action.payload;
      if (company_payroll_setting) {
        state.settings.push(company_payroll_setting);
      }

      state.saving_loading = false;
      state.saved = true;
    },

    companyPayrollSettingCreationFailed: (state, action) => {
      state.saving_loading = false;
      state.saved = false;
    },

    companyPayrollSettingUpdationRequested: (state, action) => {
      state.saving_loading = true;
      state.saved = false;
    },

    companyPayrollSettingUpdationReceived: (state, action) => {
      const { company_payroll_setting } = action.payload;

      const index = state.settings.findIndex((stat) => stat.id === company_payroll_setting.id);
      if (index !== -1) {
        state.settings[index] = company_payroll_setting
      }

      state.saving_loading = false;
      state.saved = true;
    },

    companyPayrollSettingUpdationFailed: (state, action) => {
      state.saving_loading = false;
    },

    resetActionStates: (state, action) => {
      state.loading = false;
      state.saving_loading = false;
      state.saved = false;
    },
  },
});

export default slice.reducer;

const {
  companyPayrollSettingsRequested,
  companyPayrollSettingsReceived,
  companyPayrollSettingsRequestFailed,
  companyPayrollSettingCreationRequested,
  companyPayrollSettingCreationReceived,
  companyPayrollSettingCreationFailed,
  companyPayrollSettingUpdationRequested,
  companyPayrollSettingUpdationReceived,
  companyPayrollSettingUpdationFailed,
  resetActionStates,
} = slice.actions;

const url = "v1/hrms/company_payroll_settings";

export const getCompanyPayrollSettings = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url,
      onStart: companyPayrollSettingsRequested.type,
      onSuccess: companyPayrollSettingsReceived.type,
      onError: companyPayrollSettingsRequestFailed.type,
    }),
  );
};

export const createCompanyPayrollSetting = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      method: "POST",
      data: { company_payroll_setting: payload.company_payroll_setting },
      type: "creation",
      onStart: companyPayrollSettingCreationRequested.type,
      onSuccess: companyPayrollSettingCreationReceived.type,
      onError: companyPayrollSettingCreationFailed.type,
      showAlert: true,
      successMessage: "Payroll settings added successfully!"
    }),
  );
};

export const updateCompanyPayrollSetting = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "PATCH",
      data: { company_payroll_setting: payload.company_payroll_setting },
      type: "updation",
      onStart: companyPayrollSettingUpdationRequested.type,
      onSuccess: companyPayrollSettingUpdationReceived.type,
      onError: companyPayrollSettingUpdationFailed.type,
      showAlert: true,
      successMessage: "Payroll settings updated successfully!"
    }),
  );
};

export const resetActions = () => (dispatch) => {
  return dispatch(resetActionStates());
};
