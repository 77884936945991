import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";

const slice = createSlice({
  name: "company_allowances",
  initialState: {
    list: [],
    loading: false,
    deletion_loading: false,
    saving_loading: false,
    saved: false,
  },

  reducers: {
    companyAllowancesRequested: (state, action) => {
      state.list = [];
      state.loading = true;
    },

    companyAllowancesReceived: (state, action) => {
      state.list = action.payload.list;
      state.loading = false;
    },

    companyAllowancesRequestFailed: (state, action) => {
      state.loading = false;
    },

    companyAllowanceCreationRequested: (state, action) => {
      state.saving_loading = true;
      state.saved = false;
    },

    companyAllowanceCreationReceived: (state, action) => {
      const { company_allowance } = action.payload;
      if (company_allowance) {
        state.list.push(company_allowance);
      }
      state.saving_loading = false;
      state.saved = true;
    },

    companyAllowanceCreationFailed: (state, action) => {
      state.saving_loading = false;
      state.saved = false;
    },

    companyAllowanceUpdationRequested: (state, action) => {
      state.saving_loading = true;
      state.saved = false;
    },

    companyAllowanceUpdationReceived: (state, action) => {
      const { company_allowance } = action.payload;

      const companyAllowanceIndex = state.list.findIndex(
        (stat) => stat.id === company_allowance.id
      );
      if (companyAllowanceIndex !== -1) {
        state.list[companyAllowanceIndex] = company_allowance;
      }
      state.saving_loading = false;
      state.saved = true;
    },

    companyAllowanceUpdationFailed: (state, action) => {
      state.saving_loading = false;
    },

    companyAllowanceDeletionRequested: (state, action) => {
      state.deletion_loading = true;
    },

    companyAllowanceDeletionReceived: (state, action) => {
      const { id } = action.payload;
      const companyAllowanceIndex = state.list.findIndex(
        (dep) => dep.id === id
      );
      state.list.splice(companyAllowanceIndex, 1);
      state.deletion_loading = false;
    },

    companyAllowanceDeletionFailed: (state, action) => {
      state.deletion_loading = false;
    },

    resetActionStates: (state, action) => {
      state.loading = false;
      state.deletion_loading = false;
      state.saving_loading = false;
      state.saved = false;
    },
  },
});

export default slice.reducer;

const {
  companyAllowancesRequested,
  companyAllowancesReceived,
  companyAllowancesRequestFailed,
  companyAllowanceCreationRequested,
  companyAllowanceCreationReceived,
  companyAllowanceCreationFailed,
  companyAllowanceUpdationRequested,
  companyAllowanceUpdationReceived,
  companyAllowanceUpdationFailed,
  companyAllowanceDeletionRequested,
  companyAllowanceDeletionReceived,
  companyAllowanceDeletionFailed,
  resetActionStates,
} = slice.actions;

const url = "v1/hrms/company_allowances";

export const getCompanyAllowances = (payload) => (dispatch) => {
  const endpoint = `${url}?year=${payload.year}`;
  return dispatch(
    apiCallBegan({
      url: endpoint,
      onStart: companyAllowancesRequested.type,
      onSuccess: companyAllowancesReceived.type,
      onError: companyAllowancesRequestFailed.type,
    })
  );
};

export const createCompanyAllowance = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      method: "POST",
      data: { company_allowance: payload.company_allowance },
      type: "creation",
      onStart: companyAllowanceCreationRequested.type,
      onSuccess: companyAllowanceCreationReceived.type,
      onError: companyAllowanceCreationFailed.type,
      showAlert: true,
      successMessage: "Allowance added successfully!",
    })
  );
};

export const updateCompanyAllowance = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "PATCH",
      data: { company_allowance: payload.company_allowance },
      type: "updation",
      onStart: companyAllowanceUpdationRequested.type,
      onSuccess: companyAllowanceUpdationReceived.type,
      onError: companyAllowanceUpdationFailed.type,
      showAlert: true,
      successMessage: "Allowance updated successfully!",
    })
  );
};

export const deleteCompanyAllowance = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "DELETE",
      type: "deletion",
      onStart: companyAllowanceDeletionRequested.type,
      onSuccess: companyAllowanceDeletionReceived.type,
      onError: companyAllowanceDeletionFailed.type,
      showAlert: true,
      successMessage: "Allowance deleted successfully!",
    })
  );
};

export const resetActions = () => (dispatch) => {
  return dispatch(resetActionStates());
};
