import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";

const slice = createSlice({
  name: "company_bank_accounts",
  initialState: {
    list: [],
    loading: false,
    deletion_loading: false,
    saving_loading: false,
    saved: false,
  },

  reducers: {
    companyBankAccountsRequested: (state, action) => {
      state.list = [];
      state.loading = true;
    },

    companyBankAccountsReceived: (state, action) => {
      state.list = action.payload.company_bank_accounts;
      state.loading = false;
    },

    companyBankAccountsRequestFailed: (state, action) => {
      state.loading = false;
    },

    companyBankAccountCreationRequested: (state, action) => {
      state.saving_loading = true;
      state.saved = false;
    },

    companyBankAccountCreationReceived: (state, action) => {
      const { company_bank_account } = action.payload;

      if (company_bank_account.primary) {
        state.list.forEach((account) => (account.primary = false));
      }

      state.list.push(company_bank_account);

      state.saving_loading = false;
      state.saved = true;
    },

    companyBankAccountCreationFailed: (state, action) => {
      state.saving_loading = false;
      state.saved = false;
    },

    companyBankAccountUpdationRequested: (state, action) => {
      state.saving_loading = true;
      state.saved = false;
    },

    companyBankAccountUpdationReceived: (state, action) => {
      const { company_bank_account } = action.payload;

      if (company_bank_account.primary) {
        state.list.forEach((account) => (account.primary = false));
      }

      const index = state.list.findIndex(
        (stat) => stat.id === company_bank_account.id
      );
      if (index !== -1) {
        state.list[index] = company_bank_account;
      }

      state.saving_loading = false;
      state.saved = true;
    },

    companyBankAccountUpdationFailed: (state, action) => {
      state.saving_loading = false;
    },

    companyBankAccountDeletionRequested: (state, action) => {
      state.deletion_loading = true;
    },

    companyBankAccountDeletionReceived: (state, action) => {
      const { id } = action.payload;
      const index = state.list.findIndex((dep) => dep.id === id);
      state.list.splice(index, 1);
      state.deletion_loading = false;
    },

    companyBankAccountDeletionFailed: (state, action) => {
      state.deletion_loading = false;
    },

    resetActionStates: (state, action) => {
      state.loading = false;
      state.saving_loading = false;
      state.saved = false;
      state.deletion_loading = false;
    },
  },
});

export default slice.reducer;

const {
  companyBankAccountsRequested,
  companyBankAccountsReceived,
  companyBankAccountsRequestFailed,
  companyBankAccountCreationRequested,
  companyBankAccountCreationReceived,
  companyBankAccountCreationFailed,
  companyBankAccountUpdationRequested,
  companyBankAccountUpdationReceived,
  companyBankAccountUpdationFailed,
  companyBankAccountDeletionRequested,
  companyBankAccountDeletionReceived,
  companyBankAccountDeletionFailed,
  resetActionStates,
} = slice.actions;

const url = "v1/hrms/company_bank_accounts";

export const getCompanyBankAccounts = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url,
      onStart: companyBankAccountsRequested.type,
      onSuccess: companyBankAccountsReceived.type,
      onError: companyBankAccountsRequestFailed.type,
    })
  );
};

export const createCompanyBankAccount = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      method: "POST",
      data: { company_bank_account: payload.company_bank_account },
      type: "creation",
      onStart: companyBankAccountCreationRequested.type,
      onSuccess: companyBankAccountCreationReceived.type,
      onError: companyBankAccountCreationFailed.type,
      showAlert: true,
      successMessage: "Bank account added successfully!",
    })
  );
};

export const updateCompanyBankAccount = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "PATCH",
      data: { company_bank_account: payload.company_bank_account },
      type: "updation",
      onStart: companyBankAccountUpdationRequested.type,
      onSuccess: companyBankAccountUpdationReceived.type,
      onError: companyBankAccountUpdationFailed.type,
      showAlert: true,
      successMessage: "Bank account updated successfully!",
    })
  );
};

export const deleteCompanyBankAccount = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "DELETE",
      type: "deletion",
      onStart: companyBankAccountDeletionRequested.type,
      onSuccess: companyBankAccountDeletionReceived.type,
      onError: companyBankAccountDeletionFailed.type,
      showAlert: true,
      successMessage: "Bank account deleted successfully!",
    })
  );
};

export const resetActions = () => (dispatch) => {
  return dispatch(resetActionStates());
};
