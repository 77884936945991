import React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";

const Logo = ({
  onlyIcon = false,
  name = "EORGANIZER",
  caption = "SIMPLIFYING PRODUCTIVITY",
  imgSrc = "https://assets.eorganizer.in/static-images/logo.png",
  handleClick = undefined,
}) => {
  const nameStyle = {
    width: "100%",
    fontSize: "x-large",
    display: "flex",
    justifyContent: "center",
    background: "-webkit-linear-gradient(#e110f2, #f7eeee)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    marginBlockStart: 0,
    marginBlockEnd: 0,
    marginTop: "-0.2rem",
  };

  const captionStyle = {
    width: "100%",
    fontSize: "0.6rem",
    display: "flex",
    justifyContent: "center",
    background: "-webkit-linear-gradient(#e110f2, #f7eeee)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    marginBlockStart: 0,
    marginBlockEnd: 0,
    marginTop: "-0.3rem",
  };

  return (
    <Box onClick={handleClick} sx={{ display: "flex", alignItems: "center" }}>
      <Box>
        <Avatar src={imgSrc} sx={{ borderRadius: 0 }} />
      </Box>
      {!onlyIcon && (
        <Box ml={1}>
          <h1 style={nameStyle}>{name}</h1>
          <h1 style={captionStyle}>{caption}</h1>
        </Box>
      )}
    </Box>
  );
};

export default Logo;
