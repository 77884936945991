import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Link, useLocation } from "react-router-dom";
import useTheme from "@mui/material/styles/useTheme";
import HomeActiveIcon from "@mui/icons-material/Home";
import HomeIcon from "@mui/icons-material/HomeOutlined";
import PersonIcon from "@mui/icons-material/PersonOutlineOutlined";
import PersonActiveIcon from "@mui/icons-material/Person";
import PeopleActiveIcon from "@mui/icons-material/PeopleAlt";
import PeopleIcon from "@mui/icons-material/PeopleAltOutlined";
import GroupsIcon from "@mui/icons-material/GroupsOutlined";
import GroupsActiveIcon from "@mui/icons-material/Groups";
import PolicyIcon from "@mui/icons-material/PolicyOutlined";
import PolicyActiveIcon from "@mui/icons-material/Policy";
import GoalActiveIcon from "@mui/icons-material/TrackChanges";
import GoalIcon from "@mui/icons-material/TrackChangesOutlined";
import BankIcon from "@mui/icons-material/AccountBalanceOutlined";
import BankActiveIcon from "@mui/icons-material/AccountBalance";
import SettingsActiveIcon from "@mui/icons-material/Settings";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import TaxIcon from "@mui/icons-material/PriceChangeOutlined";
import TaxActiveIcon from "@mui/icons-material/PriceChange";

const HrmsSideMenu = ({ closeMenu, loggedInUser }) => {
  const location = useLocation();

  const theme = useTheme();

  const hoverBackground = theme.custom.sideMenuItemHoverColor;

  const iconColor = theme.custom.themeContrastColor;

  const activeTextColor = theme.palette.white.main;

  const activeBackgroundColor = theme.palette.mediumBlue.main;

  const menuItems = [
    {
      text: "Home",
      activeIcon: (
        <HomeActiveIcon
          color="white"
          sx={{ fontSize: "1.3rem", fontWeight: 400 }}
        />
      ),
      icon: <HomeIcon color={iconColor} sx={{ fontSize: "1.3rem" }} />,
      path: "/hrms/home",
      altPath: "/hrms",
      endsWith: "/hrms",
    },
    {
      text: "My Space",
      activeIcon: (
        <PersonActiveIcon color="white" sx={{ fontSize: "1.3rem" }} />
      ),
      icon: <PersonIcon color={iconColor} sx={{ fontSize: "1.3rem" }} />,
      path: `/hrms/employee/${loggedInUser?.uid}/profile`,
      activeMenuItemPath: `/hrms/employee/${loggedInUser?.uid}`,
    },
    {
      text: "My Team Space",
      activeIcon: (
        <PeopleActiveIcon color="white" sx={{ fontSize: "1.3rem" }} />
      ),
      icon: <PeopleIcon color={iconColor} sx={{ fontSize: "1.3rem" }} />,
      path: "/hrms/my-team",
    },
    {
      text: "Organization Space",
      activeIcon: (
        <GroupsActiveIcon color="white" sx={{ fontSize: "1.3rem" }} />
      ),
      icon: <GroupsIcon color={iconColor} sx={{ fontSize: "1.3rem" }} />,
      path: "/hrms/organization-space",
    },
    {
      text: "Organization Policies",
      activeIcon: (
        <PolicyActiveIcon color="white" sx={{ fontSize: "1.3rem" }} />
      ),
      icon: <PolicyIcon color={iconColor} sx={{ fontSize: "1.3rem" }} />,
      path: "/hrms/organization-policies",
    },
    {
      text: "Goals Management",
      activeIcon: <GoalActiveIcon color="white" sx={{ fontSize: "1.3rem" }} />,
      icon: <GoalIcon color={iconColor} sx={{ fontSize: "1.3rem" }} />,
      path: "/hrms/goals",
    },
    {
      text: "Finance Management",
      activeIcon: <BankActiveIcon color="white" sx={{ fontSize: "1.3rem" }} />,
      icon: <BankIcon color={iconColor} sx={{ fontSize: "1.3rem" }} />,
      path: "/hrms/finance-management",
    },
    {
      text: "Tax Management",
      activeIcon: <TaxActiveIcon color="white" sx={{ fontSize: "1.3rem" }} />,
      icon: <TaxIcon color={iconColor} sx={{ fontSize: "1.3rem" }} />,
      path: "/hrms/tax-management",
    },
    {
      text: "Admin Settings",
      activeIcon: (
        <SettingsActiveIcon color="white" sx={{ fontSize: "1.3rem" }} />
      ),
      icon: <SettingsIcon color={iconColor} sx={{ fontSize: "1.3rem" }} />,
      path: "/hrms/admin-settings",
    },
  ];

  const currentPath = location.pathname;

  const isSelected = (pathname) => currentPath.includes(pathname);

  return (
    <div>
      <div />
      <List>
        {menuItems.map((item) => {
          const selected =
            isSelected(item.activeMenuItemPath || item.path || item.altPath) ||
            (item.endsWith && currentPath.endsWith(item.endsWith));
          return (
            <ListItem
              button
              key={item.text}
              component={Link}
              to={item.path}
              onClick={closeMenu}
              sx={{
                ...(selected && {
                  background: activeBackgroundColor,
                  borderRadius: "5px",
                }),
                minHeight: "40px",
                color: selected ? activeTextColor : "inherit",
                margin: "10px",
                width: "-webkit-fill-available",
                "&:hover": {
                  background: hoverBackground,
                  borderRadius: "5px",
                },
              }}
            >
              <ListItemIcon sx={{ minWidth: "40px" }}>
                {selected ? item.activeIcon : item.icon}
              </ListItemIcon>
              <Label text={item.text} selected={selected} />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};

const Label = ({ text, selected }) => {
  return (
    <div
      style={{
        fontSize: "1rem",
        fontWeight: 400,
        color: "inherit",
      }}
    >
      {text}
    </div>
  );
};

export default HrmsSideMenu;
