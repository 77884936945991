const PM_MENU = [
  { title: "Buckets", value: "buckets" },
  { title: "All Tickets", value: "all_tickets" },
  { title: "Your Tickets", value: "your_tickets" },
  { title: "Backlogs", value: "backlogs" },
];

const PM_ACTIONS = [
  {
    title: "ADD SPRINT",
    icon: "add-circle-outline",
    color: "blue",
  },
  {
    title: "SETTINGS",
    icon: "settings",
    color: "green",
  },
];

const PRIORITIES = [
  { value: "urgent", label: "URGENT", bgColor: "#FF0000", color: "#FFFFFF" },
  { value: "high", label: "HIGH", bgColor: "#FF6600", color: "#FFFFFF" },
  { value: "medium", label: "MEDIUM", bgColor: "#00FFFF", color: "#FFFFFF" },
  { value: "low", label: "LOW", bgColor: "#808080", color: "#FFFFFF" },
];

const INPUT_BORDER_COLOR = "gray";

const COLOR_CODES = {
  gray: "#808080",
  lightGray: "#D3D3D3",
  darkGray: "#5A5A5A",
  black: "#1D1D1D",
  lightBlack: "#313131",
  darkBlack: "#000000",
  red: "#FF0000",
  lightRed: "#66FFE5",
  darkRed: "#8B0000",
  green: "#00A300",
  lightGreen: "#00D100",
  darkGreen: "#007500",
  yellow: "#FFBF00",
  blue: "#0000FF",
  white: "#FFFFFF",
  aquaBlue: "#00FFFF",
};

const FONT_SIZES = {
  xs: "0.5rem",
  sm: "0.7rem",
  sm_l: "0.75rem",
  m: "0.85rem",
  l: "1rem",
  xl: "1.2rem",
  "2xl": "1.4rem",
  "3xl": "1.6rem",
  "4xl": "1.8rem",
};

const FONT_WEIGHTS = {
  normal: 400,
  semi_bold: 500,
  bold: 700,
};

const INPUT_TYPES = [
  { label: "Number", value: "number" },
  { label: "Open Input", value: "text" },
  { label: "Email", value: "email" },
  { label: "Phone No", value: "tel" },
  { label: "Link", value: "link" },
  { label: "Dropdown", value: "dropdown" },
  { label: "Date", value: "date" },
  { label: "Time", value: "time" },
  { label: "Date & Time", value: "datetime" },
];

const ATTENDANCE_TYPES = {
  work_from_home: "Work from home",
  work_from_office: "Work from office",
  remote_login: "Remote login",
  business_trip: "Business trip",
};

const SOCIAL_MEDIA_BUTTONS = [
  {
    logo: "https://assets.eorganizer.in/social-media/google-logo.svg",
    label: "Google",
    key: "google",
  },
  {
    logo: "https://assets.eorganizer.in/social-media/microsoft-logo.svg",
    label: "Microsoft",
    key: "microsoft",
  },
];

const BOX_SHADOW_LIGHT =
  "#d9d9f8 0px -0.5px 1.5px 0px,#d9d9f8 0px 1.5px 1.5px 0px";

const isMobileDevice =
  /Mobi|Android|iPhone|iPad|iPod|BlackBerry|Windows Phone|webOS/i.test(
    navigator.userAgent
  );

const isIos = /iphone|ipad|ipod/.test(navigator.userAgent.toLowerCase());

const isInStandaloneMode =
  window.matchMedia("(display-mode: standalone)").matches ||
  window.navigator.standalone;

const isPwaOnIos = isIos && isInStandaloneMode;

export {
  PM_MENU,
  COLOR_CODES,
  FONT_SIZES,
  FONT_WEIGHTS,
  PM_ACTIONS,
  PRIORITIES,
  INPUT_BORDER_COLOR,
  INPUT_TYPES,
  SOCIAL_MEDIA_BUTTONS,
  ATTENDANCE_TYPES,
  BOX_SHADOW_LIGHT,
  isMobileDevice,
  isPwaOnIos,
};
