import React, { lazy, Suspense, useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import SideArrowCollapse from "./icons/SideArrowCollapse";
import SideArrowExpand from "./icons/SideArrowExpand";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PmSideMenu from "./PmSideMenu";
import HrmsSideMenu from "./HrmsSideMenu";
import { getLoggedIntUserData, getEmployees } from "./store/users";
import { loadCompanyDetails } from "./store/common";
import Paper from "@mui/material/Paper";
import useMediaQuery from "@mui/material/useMediaQuery";
import SettingsSuggestTwoToneIcon from "@mui/icons-material/SettingsSuggestTwoTone";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Logo from "./common/Logo";
import IntelligentAppBar from "./IntelligentAppBar";
import AppPageTitle from "./AppPageTitle";
import SuspenseLoader from "./SuspenseLoader";

const Home = lazy(() => import("./pages/Home"));
const Customization = lazy(() => import("./pages/Customization"));
const Bucket = lazy(() => import("./pages/Bucket"));
const Ticket = lazy(() => import("./pages/Ticket"));
const ProductManagement = lazy(() => import("./pages/ProductManagement"));
const HrmsHome = lazy(() => import("./pages/hrms/Home"));
const HrmsEmployeeSpace = lazy(() => import("./pages/hrms/EmployeeSpace"));
const HrmsMyTeamSpace = lazy(() => import("./pages/hrms/MyTeamSpace"));
const HrmsAdminSettings = lazy(() => import("./pages/hrms/AdminSettings"));
const FinanceManagement = lazy(() => import("./pages/hrms/FinanceManagement"));
const TaxManagement = lazy(() => import("./pages/hrms/TaxManagement"));
const EmployeeDetailsFormPage = lazy(() =>
  import("./pages/hrms/EmployeeDetailsFormPage")
);
const HrmsOrganizationGoals = lazy(() =>
  import("./pages/hrms/OrganizationGoals")
);
const HrmsOrganizationSpace = lazy(() =>
  import("./pages/hrms/OrganizationSpace")
);
const HrmsOrganizationPolicies = lazy(() =>
  import("./pages/hrms/OrganizationPolicies")
);
const KnowledgeBase = lazy(() => import("./pages/KnowledgeBase"));

const drawerWidth = 260;

// const logo = "https://assets.eorganizer.in/static-images/eorg-logo.png";

const Main = styled(Paper, { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open, variant }) => ({
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: variant === "persistent" ? `-${drawerWidth}px` : 0,
    minHeight: { xs: "100%", sm: "100%", md: `calc(100vh - 3.325rem)` },
    overflowX: "auto",
    marginTop: "3.325rem",
    boxShadow: "none",
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function Layoout(props) {
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const isBelowMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  const [open, setOpen] = useState(!isSmallScreen);

  const navigate = useNavigate();

  const location = useLocation();

  const currentPath = location.pathname;

  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.users.user);
  const employeesList = useSelector(
    (state) => state.users.lightWeightEmployeesList ?? []
  );
  const companyDetails = useSelector((state) => state.common.companyDetails);

  useEffect(() => {
    if (!loggedInUser) {
      dispatch(getLoggedIntUserData());
    }
  }, [dispatch, loggedInUser]);

  useEffect(() => {
    if (employeesList.length < 1) {
      dispatch(getEmployees({ minimalInfo: true }));
    }
  }, [dispatch, employeesList]);

  useEffect(() => {
    if (!companyDetails) {
      dispatch(loadCompanyDetails());
    }
  }, [dispatch, companyDetails]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box role="presentation" sx={{ display: "flex" }}>
      <React.Fragment key="left">
        <SwipeableDrawer
          sx={{
            width: isSmallScreen ? "300px" : drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: isSmallScreen ? "300px" : drawerWidth,
              boxSizing: "border-box",
              background: "#07070f",
            },
          }}
          variant={isSmallScreen ? "temporary" : "persistent"}
          anchor="left"
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
        >
          <DrawerHeader sx={{ justifyContent: "space-between", my: 0.5 }}>
            <Logo />
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <SideArrowCollapse height={25} width={25} />
              ) : (
                <SideArrowExpand height={25} width={25} />
              )}
            </IconButton>
          </DrawerHeader>
          {currentPath.startsWith("/pm") && (
            <ListItem
              key="Customization"
              disablePadding
              sx={{
                backgroundColor: "green",
                backgroundImage:
                  "linear-gradient(285deg, #032ef7 12%, green 84%)",
              }}
            >
              <ListItemButton
                onClick={() => {
                  navigate("/pm/customization");
                  isSmallScreen && setOpen(false);
                }}
              >
                <ListItemIcon sx={{ minWidth: "35px" }}>
                  <SettingsSuggestTwoToneIcon
                    sx={{ color: "#FFF", fontWeight: 500 }}
                  />
                </ListItemIcon>
                <ListItemText primary="Customization" sx={{ color: "#FFF" }} />
              </ListItemButton>
            </ListItem>
          )}
          <Divider />
          {currentPath.startsWith("/pm") && (
            <PmSideMenu
              isMobile={isSmallScreen}
              onClose={() => setOpen(false)}
            />
          )}
          {currentPath.startsWith("/hrms") && (
            <HrmsSideMenu
              loggedInUser={loggedInUser}
              closeMenu={() => isSmallScreen && setOpen(false)}
            />
          )}
        </SwipeableDrawer>
      </React.Fragment>
      <CssBaseline />
      {isBelowMediumScreen && (
        <AppPageTitle
          loggedInUser={loggedInUser}
          currentPath={currentPath}
          handleDrawerOpen={handleDrawerOpen}
        />
      )}
      <IntelligentAppBar
        loggedInUser={loggedInUser}
        currentPath={currentPath}
        handleDrawerOpen={handleDrawerOpen}
        open={open}
        isBelowMediumScreen={isBelowMediumScreen}
        isLargeScreen={isLargeScreen}
        props={props}
      />
      <Main
        open={open}
        variant={isSmallScreen ? "temporary" : "persistent"}
        sx={{ marginBottom: { xs: "55px", sm: "55px", md: 0 } }}
      >
        <Box mb={4} sx={{ margin: "auto", maxWidth: "1200px" }}>
          <Routes>
            <Route
              path="/"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <Home theme={theme} />
                </Suspense>
              }
            />
            <Route
              path="/home"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <Home theme={theme} />
                </Suspense>
              }
            />
            <Route
              path="/pm"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <ProductManagement theme={theme} />
                </Suspense>
              }
            />
            <Route
              path="/pm/:entity/customization"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <Customization theme={theme} />
                </Suspense>
              }
            />
            <Route
              path="/pm/customization"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <Customization theme={theme} />
                </Suspense>
              }
            />
            <Route
              path="/pm/bucket/:bucketId"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <Bucket theme={theme} />
                </Suspense>
              }
            />
            <Route
              path="/pm/bucket/:bucketId/:displayType"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <Bucket theme={theme} />
                </Suspense>
              }
            />
            <Route
              path="/pm/bucket/:bucketId/ticket/:ticketId"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <Ticket theme={theme} />
                </Suspense>
              }
            />
            <Route
              path="/hrms"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <HrmsHome theme={theme} />
                </Suspense>
              }
            />
            <Route
              path="/hrms/home"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <HrmsHome theme={theme} />
                </Suspense>
              }
            />
            <Route
              path="/hrms/my-team"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <HrmsMyTeamSpace theme={theme} />
                </Suspense>
              }
            />
            <Route
              path="/hrms/my-team/:component"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <HrmsMyTeamSpace theme={theme} />
                </Suspense>
              }
            />
            <Route
              path="/hrms/my-team/:component/:componentId"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <HrmsMyTeamSpace theme={theme} />
                </Suspense>
              }
            />
            <Route
              path="/hrms/my-team/:component/:componentId/:subComponent"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <HrmsMyTeamSpace theme={theme} />
                </Suspense>
              }
            />
            <Route
              path="/hrms/my-team/:component/:componentId/:subComponent/:subComponentId/"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <HrmsMyTeamSpace theme={theme} />
                </Suspense>
              }
            />
            <Route
              path="/hrms/organization-space"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <HrmsOrganizationSpace theme={theme} />
                </Suspense>
              }
            />
            <Route
              path="/hrms/organization-space/:component"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <HrmsOrganizationSpace theme={theme} />
                </Suspense>
              }
            />
            <Route
              path="/hrms/organization-policies"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <HrmsOrganizationPolicies theme={theme} />
                </Suspense>
              }
            />
            <Route
              path="/hrms/organization-policies/:policyId"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <HrmsOrganizationPolicies theme={theme} />
                </Suspense>
              }
            />
            <Route
              path="/hrms/goals"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <HrmsOrganizationGoals theme={theme} />
                </Suspense>
              }
            />
            <Route
              path="/hrms/goals/:component"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <HrmsOrganizationGoals theme={theme} />
                </Suspense>
              }
            />
            <Route
              path="/hrms/goals/:component/:componentId"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <HrmsOrganizationGoals theme={theme} />
                </Suspense>
              }
            />
            <Route
              path="/hrms/admin-settings"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <HrmsAdminSettings theme={theme} />
                </Suspense>
              }
            />
            <Route
              path="/hrms/admin-settings/:settingType"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <HrmsAdminSettings theme={theme} />
                </Suspense>
              }
            />
            <Route
              path="/hrms/finance-management"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <FinanceManagement theme={theme} />
                </Suspense>
              }
            />
            <Route
              path="/hrms/finance-management/:component"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <FinanceManagement theme={theme} />
                </Suspense>
              }
            />
            <Route
              path="/hrms/finance-management/:component/:tab"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <FinanceManagement theme={theme} />
                </Suspense>
              }
            />
            <Route
              path="/hrms/tax-management"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <TaxManagement theme={theme} />
                </Suspense>
              }
            />
            <Route
              path="/hrms/tax-management/:component"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <TaxManagement theme={theme} />
                </Suspense>
              }
            />
            <Route
              path="/hrms/tax-management/:component/:tab"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <TaxManagement theme={theme} />
                </Suspense>
              }
            />
            <Route
              path="/hrms/admin-settings/employees/:employeeId/:action/:section"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <EmployeeDetailsFormPage theme={theme} />
                </Suspense>
              }
            />
            <Route
              path="/hrms/admin-settings/employees/:employeeId/:action"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <EmployeeDetailsFormPage theme={theme} />
                </Suspense>
              }
            />
            <Route
              path="/hrms/employee/:employeeId/:component"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <HrmsEmployeeSpace theme={theme} />
                </Suspense>
              }
            />
            <Route
              path="/hrms/employee/:employeeId/:component/:componentId"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <HrmsEmployeeSpace theme={theme} />
                </Suspense>
              }
            />
            <Route
              path="/hrms/employee/:employeeId/:component/:componentId/:subComponent"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <HrmsEmployeeSpace theme={theme} />
                </Suspense>
              }
            />
            <Route
              path="/kb"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <KnowledgeBase theme={theme} />
                </Suspense>
              }
            />
          </Routes>
        </Box>
      </Main>
    </Box>
  );
}
