import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";

const slice = createSlice({
  name: "company_variable_pay_settings",
  initialState: {
    list: [],
    loading: false,
    deletion_loading: false,
    saving_loading: false,
    saved: false,
  },

  reducers: {
    companyVariablePaySettingsRequested: (state, action) => {
      state.list = [];
      state.loading = true;
    },

    companyVariablePaySettingsReceived: (state, action) => {
      state.list = action.payload.company_variable_pay_settings;
      state.loading = false;
    },

    companyVariablePaySettingsRequestFailed: (state, action) => {
      state.loading = false;
    },

    companyVariablePaySettingCreationRequested: (state, action) => {
      state.saving_loading = true;
      state.saved = false;
    },

    companyVariablePaySettingCreationReceived: (state, action) => {
      const { company_variable_pay_setting } = action.payload;
      state.list.push(company_variable_pay_setting);
      state.saving_loading = false;
      state.saved = true;
    },

    companyVariablePaySettingCreationFailed: (state, action) => {
      state.saving_loading = false;
      state.saved = false;
    },

    companyVariablePaySettingUpdationRequested: (state, action) => {
      state.saving_loading = true;
      state.saved = false;
    },

    companyVariablePaySettingUpdationReceived: (state, action) => {
      const { company_variable_pay_setting } = action.payload;

      const index = state.list.findIndex(
        (stat) => stat.id === company_variable_pay_setting.id
      );
      if (index !== -1) {
        state.list[index] = company_variable_pay_setting;
      }

      state.saving_loading = false;
      state.saved = true;
    },

    companyVariablePaySettingUpdationFailed: (state, action) => {
      state.saving_loading = false;
    },

    companyVariablePaySettingDeletionRequested: (state, action) => {
      state.deletion_loading = true;
    },

    companyVariablePaySettingDeletionReceived: (state, action) => {
      const { id } = action.payload;
      const index = state.list.findIndex((dep) => dep.id === id);
      state.list.splice(index, 1);
      state.deletion_loading = false;
    },

    companyVariablePaySettingDeletionFailed: (state, action) => {
      state.deletion_loading = false;
    },

    resetActionStates: (state, action) => {
      state.loading = false;
      state.saving_loading = false;
      state.saved = false;
      state.deletion_loading = false;
    },
  },
});

export default slice.reducer;

const {
  companyVariablePaySettingsRequested,
  companyVariablePaySettingsReceived,
  companyVariablePaySettingsRequestFailed,
  companyVariablePaySettingCreationRequested,
  companyVariablePaySettingCreationReceived,
  companyVariablePaySettingCreationFailed,
  companyVariablePaySettingUpdationRequested,
  companyVariablePaySettingUpdationReceived,
  companyVariablePaySettingUpdationFailed,
  companyVariablePaySettingDeletionRequested,
  companyVariablePaySettingDeletionReceived,
  companyVariablePaySettingDeletionFailed,
  resetActionStates,
} = slice.actions;

const url = "v1/hrms/company_variable_pay_settings";

export const getCompanyVariablePaySettings = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url,
      onStart: companyVariablePaySettingsRequested.type,
      onSuccess: companyVariablePaySettingsReceived.type,
      onError: companyVariablePaySettingsRequestFailed.type,
    })
  );
};

export const createCompanyVariablePaySetting = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      method: "POST",
      data: { company_variable_pay_setting: payload.company_variable_pay_setting },
      type: "creation",
      onStart: companyVariablePaySettingCreationRequested.type,
      onSuccess: companyVariablePaySettingCreationReceived.type,
      onError: companyVariablePaySettingCreationFailed.type,
      showAlert: true,
      successMessage: "Varibale pay settings added successfully!",
    })
  );
};

export const updateCompanyVariablePaySetting = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "PATCH",
      data: { company_variable_pay_setting: payload.company_variable_pay_setting },
      type: "updation",
      onStart: companyVariablePaySettingUpdationRequested.type,
      onSuccess: companyVariablePaySettingUpdationReceived.type,
      onError: companyVariablePaySettingUpdationFailed.type,
      showAlert: true,
      successMessage: "Varibale pay settings updated successfully!",
    })
  );
};

export const deleteCompanyVariablePaySetting = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "DELETE",
      type: "deletion",
      onStart: companyVariablePaySettingDeletionRequested.type,
      onSuccess: companyVariablePaySettingDeletionReceived.type,
      onError: companyVariablePaySettingDeletionFailed.type,
      showAlert: true,
      successMessage: "Varibale pay setting deleted successfully!",
    })
  );
};

export const resetActions = () => (dispatch) => {
  return dispatch(resetActionStates());
};
