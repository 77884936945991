import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";

const slice = createSlice({
  name: "finance",
  initialState: {
    declarations: [],
    declarations_loading: false,
    declaration_summary: {},
    declaration_summary_loading: false,
    deductions: {},
    deductions_loading: false,
    allowances_without_grouping: [],
    allowances: {},
    allowances_loading: false,
    employee_tax_regime: null,
    employee_tax_regime_loading: false,
    declaration_proof: {},
    proof_uploading: false,
    proof_uploaded: true,
    upload_progress: null,
    declaration_deletion_loading: false,
    declaration_deleted: false,
    declaration_deletion: {},
    declaration_updation: {},
    salary_components_loading: false,
    salary_components: { earnings: [], deductions: [] },
    employee_salary_details_loading: false,
    employee_salary_details: { salary_components: [] },
    employee_payroll_details: null,
    employee_payroll_details_loading: false,
    employee_salary_history: [],
    employee_salary_history_loading: false,
    employees_investment_declarations: [],
    employees_investment_declarations_loading: false,
    refreshing_declartions_employees: [],
  },

  reducers: {
    declarationsRequested: (state, action) => {
      state.declarations = [];
      state.declarations_loading = true;
    },

    declarationsReceived: (state, action) => {
      state.declarations = action.payload.declarations;
      state.declarations_loading = false;
    },

    declarationsRequestFailed: (state, action) => {
      state.declarations_loading = false;
    },

    declarationSummaryRequested: (state, action) => {
      state.declaration_summary_loading = true;
    },

    declarationSummaryReceived: (state, action) => {
      state.declaration_summary =
        action.payload.declaration_summary?.details || {};
      state.declaration_summary_loading = false;
    },

    declarationSummaryRequestFailed: (state, action) => {
      state.declaration_summary_loading = false;
    },

    deductionsRequested: (state, action) => {
      state.deductions_loading = true;
    },

    deductionsReceived: (state, action) => {
      state.deductions = action.payload.deductions;
      state.deductions_loading = false;
    },

    deductionsRequestFailed: (state, action) => {
      state.deductions_loading = false;
    },

    allowancesRequested: (state, action) => {
      state.allowances_loading = true;
    },

    allowancesReceived: (state, action) => {
      if (action.extraInfo.without_grouping) {
        state.allowances_without_grouping = action.payload.allowances;
      } else {
        state.allowances = action.payload.allowances;
      }
      state.allowances_loading = false;
    },

    allowancesRequestFailed: (state, action) => {
      state.allowances_loading = false;
    },

    salaryComponentsRequested: (state, action) => {
      state.salary_components_loading = true;
    },

    salaryComponentsReceived: (state, action) => {
      state.salary_components = action.payload.salary_components;
      state.salary_components_loading = false;
    },

    salaryComponentsRequestFailed: (state, action) => {
      state.salary_components = false;
      state.salary_components_loading = false;
    },

    employeeSalaryRequested: (state, action) => {
      state.employee_salary_details = { salary_components: [] };
      state.employee_salary_details_loading = true;
    },

    employeeSalaryReceived: (state, action) => {
      state.employee_salary_details = action.payload.salary;
      state.employee_salary_details_loading = false;
    },

    employeeSalaryRequestFailed: (state, action) => {
      state.employee_salary_details_loading = false;
    },

    employeeSalaryHistoryRequested: (state, action) => {
      state.employee_salary_history = [];
      state.employee_salary_history_loading = true;
    },

    employeeSalaryHistoryReceived: (state, action) => {
      state.employee_salary_history = action.payload.salary_history;
      state.employee_salary_history_loading = false;
    },

    employeeSalaryHistoryRequestFailed: (state, action) => {
      state.employee_salary_history_loading = false;
    },

    employeeSalaryDetailsUpdationRequested: (state, action) => {
      state.employee_salary_details_updation_loading = true;
    },

    employeeSalaryDetailsUpdationReceived: (state, action) => {
      state.employee_salary_details = action.payload.salary;
      state.employee_salary_details_updation_loading = false;
    },

    employeeSalaryDetailsUpdationRequestFailed: (state, action) => {
      state.employee_salary_details_updation_loading = false;
    },

    employeePayrollDetailsRequested: (state, action) => {
      state.employee_payroll_details_loading = true;
    },

    employeePayrollDetailsReceived: (state, action) => {
      state.employee_payroll_details = action.payload.payroll_detail;
      state.employee_payroll_details_loading = false;
    },

    employeePayrollDetailsRequestFailed: (state, action) => {
      state.employee_payroll_details_loading = false;
    },

    employeePayrollDetailsUpdationRequested: (state, action) => {
      state.employee_payroll_details_updation_loading = true;
    },

    employeePayrollDetailsUpdationReceived: (state, action) => {
      state.employee_payroll_details = action.payload.payroll_detail;
      state.employee_payroll_details_updation_loading = false;
    },

    employeePayrollDetailsUpdationRequestFailed: (state, action) => {
      state.employee_payroll_details_updation_loading = false;
    },

    employeesInvestmentDeclarationsRequested: (state, action) => {
      if (action.requestType === "single-employee-data") {
        state.refreshing_declartions_employees = [
          ...state.refreshing_declartions_employees,
          action.extraInfo.employee_id,
        ];
      } else {
        state.employees_investment_declarations_loading = true;
      }
    },

    employeesInvestmentDeclarationsReceived: (state, action) => {
      if (action.requestType === "single-employee-data") {
        const declaration = action.payload.declaration_summary;
        const index = state.employees_investment_declarations.findIndex(
          (item) =>
            item.user_id === declaration.user_id &&
            item.financial_year === declaration.financial_year
        );
        if (index > -1) {
          state.employees_investment_declarations[index] = declaration;
        }
        state.refreshing_declartions_employees =
          state.refreshing_declartions_employees.filter(
            (i) => i !== action.extraInfo.employee_id
          );
      } else {
        state.employees_investment_declarations = action.payload.declarations;
        state.employees_investment_declarations_loading = false;
      }
    },

    employeesInvestmentDeclarationsRequestFailed: (state, action) => {
      if (action.requestType === "single-employee-data") {
        state.refreshing_declartions_employees =
          state.refreshing_declartions_employees.filter(
            (i) => i !== action.extraInfo.employee_id
          );
      } else {
        state.employees_investment_declarations_loading = false;
      }
    },

    employeeDeclarationSummaryRefreshRequested: (state, action) => {
      state.refreshing_declartions_employees = [
        ...state.refreshing_declartions_employees,
        action.extraInfo.employee_id,
      ];
    },

    employeeDeclarationSummaryRefreshReceived: (state, action) => {
      const summary = action.payload.declaration_summary;
      const index = state.employees_investment_declarations.findIndex(
        (item) =>
          item.employee.uid === summary.employee.uid &&
          item.financial_year === summary.financial_year
      );
      if (index > -1) {
        state.employees_investment_declarations[index] = summary;
      }
      state.refreshing_declartions_employees =
        state.refreshing_declartions_employees.filter(
          (i) => i !== action.extraInfo.employee_id
        );
    },

    employeeDeclarationSummaryRefreshRequestFailed: (state, action) => {
      state.refreshing_declartions_employees =
        state.refreshing_declartions_employees.filter(
          (i) => i !== action.extraInfo.employee_id
        );
    },

    employeeTaxRegimeRequested: (state, action) => {
      state.employee_tax_regime_loading = true;
    },

    employeeTaxRegimeReceived: (state, action) => {
      state.employee_tax_regime = action.payload.tax_regime;
      state.employee_tax_regime_loading = false;
    },

    employeeTaxRegimeRequestFailed: (state, action) => {
      state.employee_tax_regime_loading = false;
    },

    employeeTaxRegimeUpdateRequested: (state, action) => {
      state.employee_tax_regime_updating = true;
      state.employee_tax_regime_saved = false;
    },

    employeeTaxRegimeUpdateReceived: (state, action) => {
      state.employee_tax_regime = action.payload.tax_regime;
      state.employee_tax_regime_updating = false;
      state.employee_tax_regime_saved = true;
    },

    employeeTaxRegimeUpdateRequestFailed: (state, action) => {
      state.employee_tax_regime_updating = false;
      state.employee_tax_regime_saved = false;
    },

    declarationUpdateRequested: (state, action) => {
      state.declaration_updation[action.extraInfo.identifier] = {
        loading: true,
        updated: false,
      };
    },

    declarationUpdateReceived: (state, action) => {
      const { investment_declaration } = action.payload;
      if (action.requestType === "hr-updation") {
        const declarationIndex = state.declarations.findIndex(
          (declaration) => declaration.uid === investment_declaration.uid
        );
        if (declarationIndex > -1) {
          state.declarations[declarationIndex] = investment_declaration;
        }
      } else {
        const declarationIndex = state.declarations.findIndex(
          (declaration) => declaration.id === investment_declaration.id
        );

        if (declarationIndex > -1) {
          state.declarations[declarationIndex] = investment_declaration;
        } else {
          state.declarations.push(investment_declaration);
        }
      }

      state.declaration_updation[action.extraInfo.identifier] = {
        loading: false,
        updated: true,
      };
    },

    declarationUpdateFailed: (state, action) => {
      state.declaration_updation[action.extraInfo.identifier] = {
        loading: false,
        updated: false,
      };
    },

    proofUploadRequested: (state, action) => {
      // state.proof_uploading = true;
      state.declaration_proof[action.extraInfo.identifier] = {
        uploading: true,
        proof: null,
        progress: 0,
      };
    },

    proofUploadReceived: (state, action) => {
      // state.proof_uploading = false;
      state.declaration_proof[action.extraInfo.identifier].uploading = false;
      state.declaration_proof[action.extraInfo.identifier].proof =
        action.payload.investment_proof;
    },

    proofUploadFailed: (state, action) => {
      // state.proof_uploading = false;
      state.declaration_proof[action.extraInfo.identifier].uploading = false;
    },

    updateUploadProgress: (state, action) => {
      // state.upload_progress = action.payload.progress;
      state.declaration_proof[action.payload.identifier].progress =
        action.payload.progress;
    },

    proofsBulkDeletionRequested: (state, action) => {},

    proofsBulkDeletionReceived: (state, action) => {},

    proofsBulkDeletionFailed: (state, action) => {},

    proofDeletionRequested: () => {},

    proofDeletionReceived: (state, action) => {
      const { declaration_id, proof_id } = action.payload;

      const declarationIndex = declaration_id
        ? state.declarations.findIndex(
            (declaration) => declaration.id === declaration_id
          )
        : -1;

      if (declarationIndex > -1) {
        state.declarations[declarationIndex].proofs = state.declarations[
          declarationIndex
        ].proofs.filter((proof) => proof.uid !== proof_id);
      }
    },

    proofDeletionFailed: () => {},

    declarationDeletionRequested: (state, action) => {
      state.declaration_deletion[action.extraInfo.identifier] = {
        loading: true,
        deleted: false,
      };
    },

    declarationDeletionReceived: (state, action) => {
      const { id } = action.payload;
      const declarationIndex = state.declarations.findIndex(
        (declaration) => declaration.id === id
      );
      state.declarations.splice(declarationIndex, 1);
      state.declaration_deletion[action.extraInfo.identifier] = {
        loading: false,
        deleted: true,
      };
    },

    declarationDeletionFailed: (state, action) => {
      state.declaration_deletion[action.extraInfo.identifier] = {
        loading: false,
        deleted: false,
      };
    },

    resetActionStates: (state, action) => {
      state.declaration_deletion[action.payload.identifier] = {
        loading: false,
        deleted: false,
      };
      state.declaration_updation[action.payload.identifier] = {
        loading: false,
        updated: false,
      };
    },
  },
});

export default slice.reducer;

const {
  employeeTaxRegimeRequested,
  employeeTaxRegimeReceived,
  employeeTaxRegimeRequestFailed,
  employeeTaxRegimeUpdateRequested,
  employeeTaxRegimeUpdateReceived,
  employeeTaxRegimeUpdateRequestFailed,
  declarationsRequested,
  declarationsReceived,
  declarationsRequestFailed,
  allowancesRequested,
  allowancesReceived,
  allowancesRequestFailed,
  deductionsRequested,
  deductionsReceived,
  deductionsRequestFailed,
  declarationUpdateRequested,
  declarationUpdateReceived,
  declarationUpdateFailed,
  proofUploadRequested,
  proofUploadReceived,
  proofUploadFailed,
  updateUploadProgress,
  proofsBulkDeletionRequested,
  proofsBulkDeletionReceived,
  proofsBulkDeletionFailed,
  proofDeletionRequested,
  proofDeletionReceived,
  proofDeletionFailed,
  declarationDeletionRequested,
  declarationDeletionReceived,
  declarationDeletionFailed,
  salaryComponentsRequested,
  salaryComponentsReceived,
  salaryComponentsRequestFailed,
  employeeSalaryRequested,
  employeeSalaryReceived,
  employeeSalaryRequestFailed,
  employeeSalaryHistoryRequested,
  employeeSalaryHistoryReceived,
  employeeSalaryHistoryRequestFailed,
  employeeSalaryDetailsUpdationRequested,
  employeeSalaryDetailsUpdationReceived,
  employeeSalaryDetailsUpdationRequestFailed,
  employeePayrollDetailsRequested,
  employeePayrollDetailsReceived,
  employeePayrollDetailsRequestFailed,
  employeePayrollDetailsUpdationRequested,
  employeePayrollDetailsUpdationReceived,
  employeePayrollDetailsUpdationRequestFailed,
  declarationSummaryRequested,
  declarationSummaryReceived,
  declarationSummaryRequestFailed,
  employeesInvestmentDeclarationsRequested,
  employeesInvestmentDeclarationsReceived,
  employeesInvestmentDeclarationsRequestFailed,
  employeeDeclarationSummaryRefreshRequested,
  employeeDeclarationSummaryRefreshReceived,
  employeeDeclarationSummaryRefreshRequestFailed,
  resetActionStates,
} = slice.actions;

export const getEmployeeTaxRegime = (payload) => (dispatch) => {
  const endpoint = `v1/hrms/employees/${payload.employee_id}/tax_regimes/${payload.year}`;
  return dispatch(
    apiCallBegan({
      url: endpoint,
      onStart: employeeTaxRegimeRequested.type,
      onSuccess: employeeTaxRegimeReceived.type,
      onError: employeeTaxRegimeRequestFailed.type,
    })
  );
};

export const saveEmployeeTaxRegime = (payload) => (dispatch) => {
  const endpoint = `v1/hrms/employees/${payload.employee_id}/tax_regimes`;
  return dispatch(
    apiCallBegan({
      url: endpoint,
      method: "POST",
      data: payload,
      type: "creation",
      onStart: employeeTaxRegimeUpdateRequested.type,
      onSuccess: employeeTaxRegimeUpdateReceived.type,
      onError: employeeTaxRegimeUpdateRequestFailed.type,
      showAlert: true,
      successMessage: "Tax regime updated successfully!",
    })
  );
};

export const getEmployeeTaxDeclarations = (payload) => (dispatch) => {
  let endpoint = `v1/hrms/employees/${payload.employee_id}/investment_declarations?year=${payload.year}`;
  if (payload.include_declarable_data) {
    endpoint = `${endpoint}&include_declarable_data=true`;
  }
  return dispatch(
    apiCallBegan({
      url: endpoint,
      onStart: declarationsRequested.type,
      onSuccess: declarationsReceived.type,
      onError: declarationsRequestFailed.type,
    })
  );
};

export const getEmployeeTaxDeclarationSummary = (payload) => (dispatch) => {
  const endpoint = `v1/hrms/employees/${payload.employee_id}/investment_declaration_summaries/${payload.id}?year=${payload.year}`;
  return dispatch(
    apiCallBegan({
      url: endpoint,
      onStart: declarationSummaryRequested.type,
      onSuccess: declarationSummaryReceived.type,
      onError: declarationSummaryRequestFailed.type,
    })
  );
};

export const getDeductions = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `v1/hrms/finance/deductions?tax_regime=${payload.tax_regime}`,
      onStart: deductionsRequested.type,
      onSuccess: deductionsReceived.type,
      onError: deductionsRequestFailed.type,
    })
  );
};

export const getAllowances =
  (payload = {}) =>
  (dispatch) => {
    const queryString = new URLSearchParams(payload).toString();
    let endpoint = "v1/hrms/finance/allowances";
    if (queryString) {
      endpoint = `${endpoint}?${queryString}`;
    }
    return dispatch(
      apiCallBegan({
        url: endpoint,
        onStart: allowancesRequested.type,
        onSuccess: allowancesReceived.type,
        onError: allowancesRequestFailed.type,
        extraInfo: { without_grouping: payload.without_grouping },
      })
    );
  };

export const getSalaryComponents = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "v1/hrms/finance/salary_components",
      onStart: salaryComponentsRequested.type,
      onSuccess: salaryComponentsReceived.type,
      onError: salaryComponentsRequestFailed.type,
    })
  );
};

export const getEmployeeSalaryDetails = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `v1/hrms/employees/${payload.employee_id}/finance/salary_details`,
      onStart: employeeSalaryRequested.type,
      onSuccess: employeeSalaryReceived.type,
      onError: employeeSalaryRequestFailed.type,
    })
  );
};

export const updateEmployeeSalaryDetails = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `v1/hrms/employees/${payload.employee_id}/finance/update_salary_details`,
      method: "POST",
      data: payload.body,
      type: "updation",
      onStart: employeeSalaryDetailsUpdationRequested.type,
      onSuccess: employeeSalaryDetailsUpdationReceived.type,
      onError: employeeSalaryDetailsUpdationRequestFailed.type,
      showAlert: true,
      successMessage: "Salary details updated!",
      showErrorAlert: true,
    })
  );
};

export const getEmployeeSalaryHistory = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `v1/hrms/employees/${payload.employee_id}/finance/salary_history`,
      onStart: employeeSalaryHistoryRequested.type,
      onSuccess: employeeSalaryHistoryReceived.type,
      onError: employeeSalaryHistoryRequestFailed.type,
    })
  );
};

export const getEmployeePayrollDetails = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `v1/hrms/employees/${payload.employee_id}/finance/payroll_details`,
      onStart: employeePayrollDetailsRequested.type,
      onSuccess: employeePayrollDetailsReceived.type,
      onError: employeePayrollDetailsRequestFailed.type,
    })
  );
};

export const getEmployeesInvestmentDeclarations = (payload) => (dispatch) => {
  let endpoint = `v1/hrms/finance/employees_investment_declarations?year=${payload.year}`;
  if (payload.status && payload.status !== "all") {
    endpoint = `${endpoint}&status=${payload.status}`;
  }
  return dispatch(
    apiCallBegan({
      url: endpoint,
      onStart: employeesInvestmentDeclarationsRequested.type,
      onSuccess: employeesInvestmentDeclarationsReceived.type,
      onError: employeesInvestmentDeclarationsRequestFailed.type,
      showErrorAlert: true,
    })
  );
};

export const getEmployeeUpdatedInvestmentDeclaration =
  (payload) => (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: `v1/hrms/employees/${payload.employee_id}/investment_declaration_summaries/${payload.id}?send_employee_data=true`,
        onStart: employeesInvestmentDeclarationsRequested.type,
        onSuccess: employeesInvestmentDeclarationsReceived.type,
        onError: employeesInvestmentDeclarationsRequestFailed.type,
        type: "single-employee-data",
        showErrorAlert: true,
        extraInfo: { employee_id: payload.employee_id },
      })
    );
  };

export const refreshEmployeeDeclarationCalculations =
  (payload) => (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: `v1/hrms/employees/${payload.employee_id}/investment_declaration_summaries/${payload.year}/refresh`,
        onStart: employeeDeclarationSummaryRefreshRequested.type,
        onSuccess: employeeDeclarationSummaryRefreshReceived.type,
        onError: employeeDeclarationSummaryRefreshRequestFailed.type,
        extraInfo: { employee_id: payload.employee_id },
        showErrorAlert: true,
      })
    );
  };

export const updateEmployeePayrollDetails = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `v1/hrms/employees/${payload.employee_id}/finance/update_payroll_details`,
      method: "POST",
      data: { payroll_detail: payload.payroll_detail },
      type: "updation",
      onStart: employeePayrollDetailsUpdationRequested.type,
      onSuccess: employeePayrollDetailsUpdationReceived.type,
      onError: employeePayrollDetailsUpdationRequestFailed.type,
      showAlert: true,
      successMessage: "Payroll details updated!",
      showErrorAlert: true,
    })
  );
};

export const uploadInvestmentProof = (payload) => (dispatch) => {
  const endpoint = `v1/hrms/employees/${payload.get(
    "employee_id"
  )}/investment_proofs`;
  return dispatch(
    apiCallBegan({
      url: endpoint,
      method: "POST",
      data: payload,
      type: "creation",
      onStart: proofUploadRequested.type,
      onSuccess: proofUploadReceived.type,
      onError: proofUploadFailed.type,
      extraHeaders: { "Content-Type": "multipart/form-data" },
      onProgress: (progress) =>
        updateUploadProgress({
          identifier: payload.get("identifier"),
          progress,
        }),
      extraInfo: { identifier: payload.get("identifier") },
    })
  );
};

export const bulkDeleteInvestmentProofs = (payload) => (dispatch) => {
  const endpoint = `v1/hrms/employees/${payload.employee_id}/investment_proofs/bulk_delete`;
  return dispatch(
    apiCallBegan({
      url: endpoint,
      method: "PATCH",
      data: { ids: payload.ids },
      type: "deletion",
      onStart: proofsBulkDeletionRequested.type,
      onSuccess: proofsBulkDeletionReceived.type,
      onError: proofsBulkDeletionFailed.type,
    })
  );
};

export const deleteInvestmentProof = (payload) => (dispatch) => {
  const endpoint = `v1/hrms/employees/${payload.employee_id}/investment_proofs/${payload.id}`;
  return dispatch(
    apiCallBegan({
      url: endpoint,
      method: "DELETE",
      type: "deletion",
      onStart: proofDeletionRequested.type,
      onSuccess: proofDeletionReceived.type,
      onError: proofDeletionFailed.type,
      showErrorAlert: true,
    })
  );
};

export const saveInvestmentDeclaration = (payload) => (dispatch) => {
  const endpoint = `v1/hrms/employees/${payload.employee_id}/investment_declarations`;
  return dispatch(
    apiCallBegan({
      url: endpoint,
      method: "POST",
      data: payload,
      type: "creation",
      onStart: declarationUpdateRequested.type,
      onSuccess: declarationUpdateReceived.type,
      onError: declarationUpdateFailed.type,
      successMessage: "Investment declaration updated successfully!",
      showAlert: true,
      showErrorAlert: true,
      extraInfo: { identifier: payload.identifier },
    })
  );
};

export const updateInvestmentDeclaration = (payload) => (dispatch) => {
  let endpoint = `v1/hrms/employees/${payload.employee_id}/investment_declarations/${payload.id}`;
  if (payload.include_declarable_data) {
    endpoint = `${endpoint}?include_declarable_data=true`;
  }
  return dispatch(
    apiCallBegan({
      url: endpoint,
      method: "PATCH",
      data: { investment_declaration: payload.investment_declaration },
      type: "hr-updation",
      onStart: declarationUpdateRequested.type,
      onSuccess: declarationUpdateReceived.type,
      onError: declarationUpdateFailed.type,
      successMessage:
        payload.message || "Investment declaration updated successfully!",
      showAlert: true,
      showErrorAlert: true,
      extraInfo: { identifier: payload.id },
    })
  );
};

export const deleteHraResidency = (payload) => (dispatch) => {
  const endpoint = `v1/hrms/employees/${payload.employee_id}/investment_declarations/${payload.declaration_id}/delete_residency/${payload.residency_id}`;
  return dispatch(
    apiCallBegan({
      url: endpoint,
      method: "DELETE",
      type: "deletion",
      onStart: declarationUpdateRequested.type,
      onSuccess: declarationUpdateReceived.type,
      onError: declarationUpdateFailed.type,
      showAlert: true,
      successMessage: "HRA allowance details updated!",
      showErrorAlert: true,
      extraInfo: { identifier: payload.identifier },
    })
  );
};

export const deleteInvestmentDeclaration = (payload) => (dispatch) => {
  const endpoint = `v1/hrms/employees/${payload.employee_id}/investment_declarations/${payload.id}`;
  return dispatch(
    apiCallBegan({
      url: endpoint,
      method: "DELETE",
      type: "deletion",
      onStart: declarationDeletionRequested.type,
      onSuccess: declarationDeletionReceived.type,
      onError: declarationDeletionFailed.type,
      showErrorAlert: true,
      extraInfo: { identifier: payload.identifier },
    })
  );
};

export const resetActions = (payload) => (dispatch) => {
  return dispatch(resetActionStates({ identifier: payload.identifier }));
};
