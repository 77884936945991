import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import AppMainMenuIconComponent from "./icons/AppMainMenuIconComponent";
import AccountDropdown from "./AccountDropdown";
import AppPageTitle from "./AppPageTitle";
import useTheme from "@mui/material/styles/useTheme";

import HomeIcon from "./icons/HomeIcon";
import HIcon from "./icons/HIcon";
import PIcon from "./icons/PIcon";
import KIcon from "./icons/KIcon";

const MainMenu = ({ currentPath, handleDrawerOpen, loggedInUser }) => {
  const [anchorElNav, setAnchorElNav] = useState(null);

  const navigate = useNavigate();

  const theme = useTheme();

  const navigateTo = (path) => {
    navigate(path);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const pages = [
    {
      key: "/home",
      value: "Home",
      icon: <HomeIcon height={24} width={24} />,
      activeIcon: (
        <HomeIcon
          active={true}
          strokeColor={theme.custom.appMainMenuActiveColor}
          height={24}
          width={24}
        />
      ),
    },
    {
      key: "/pm",
      value: "Product Management",
      icon: <PIcon height={23} width={23} />,
      activeIcon: (
        <PIcon
          active={true}
          strokeColor={theme.custom.appMainMenuActiveColor}
          height={23}
          width={23}
        />
      ),
    },
    {
      key: "/hrms",
      value: "Hrms",
      icon: <HIcon height={23} width={23} />,
      activeIcon: (
        <HIcon
          active={true}
          strokeColor={theme.custom.appMainMenuActiveColor}
          height={23}
          width={23}
        />
      ),
    },
    {
      key: "/kb",
      value: "Knowledge Base",
      icon: <KIcon height={23} width={23} />,
      activeIcon: (
        <KIcon
          active={true}
          strokeColor={theme.custom.appMainMenuActiveColor}
          height={23}
          width={23}
        />
      ),
    },
  ];

  return (
    <Container maxWidth="xl" sx={{ padding: "0px !important" }}>
      <Toolbar disableGutters>
        <AppPageTitle
          loggedInUser={loggedInUser}
          currentPath={currentPath}
          handleDrawerOpen={handleDrawerOpen}
          renderOnlyTitle={true}
        />
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <AppMainMenuIconComponent handleOpenNavMenu={handleOpenNavMenu} />
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            PaperProps={{
              style: {
                borderRadius: "12px",
                padding: "1rem",
                minWidth: "280px",
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {pages.map((page, index) => {
              const isActive = currentPath.startsWith(page.key);
              return (
                <Box key={index} mb={0.5}>
                  <Button
                    fullWidth
                    key={page.key}
                    onClick={() => {
                      setAnchorElNav(null);
                      navigateTo(page.key);
                    }}
                    sx={{
                      height: "2.5rem",
                      px: "1rem",
                      justifyContent: "flex-start",
                    }}
                    startIcon={isActive ? page.activeIcon : page.icon}
                    color="default"
                  >
                    <Typography
                      fontWeight={400}
                      variant="h6"
                      sx={{ fontSize: "1rem" }}
                      {...(isActive && {
                        color: theme.custom.appMainMenuActiveColor,
                      })}
                    >
                      {page.value}
                    </Typography>
                  </Button>
                </Box>
              );
            })}
          </Menu>
          <AccountDropdown />
        </Box>
      </Toolbar>
    </Container>
  );
};
export default MainMenu;
